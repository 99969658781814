html,
body {
    height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #ffc107;

  /* Chrome only at the moment, disable pull to refresh in PWA */
  overscroll-behavior-y: contain;
}

body:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: white;
}

#root {
  background-color: white;

  min-height: 100%;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

a {
  color: #00b0ff;
}
