.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.App-drawer-header {
  background-color: #555;
  padding: 10px;
}

.App-drawer-logo {
  width: 60px;
  margin-bottom: 10px;
}
