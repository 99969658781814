.Container {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .Container {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 750px;
  }
}
@media (min-width: 992px) {
  .Container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .Container {
    width: 1170px;
  }
}

.Container-inner {
  padding: 15px;
}
