.withSpin-spin {
  animation: withSpin-spin 2s infinite linear;
}

@keyframes withSpin-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

